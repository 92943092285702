import React, { useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core"
import * as Utils from "@utils"
import styled from "styled-components"
import * as IconsGallery from "@iconsGallery"
import { makeStyles } from "@material-ui/core/styles"
import { useStyles } from "@material-ui/pickers/views/Calendar/Day"
import Autocomplete from "@material-ui/lab/Autocomplete"
import _ from "lodash"
import { render } from "@testing-library/react"

const usestyles = makeStyles({
  paper: {
    border: "1px solid grey",
  },
})

function DropdownFilter(props) {
  let {
    list = [],
    defaultValue = "",
    selectedValue = "",
    onChangeEvent = () => { },
    popupOpen = false,
    onInputChangeEvent = () => { },
    setPopupOpen = () => { },
    disableClearable = true,
    classNameTextField = "",
    touchedControl = "",
    errorControl = "",
    placeholder = "",
    style = {},
    disabled = false,
    name = "",
    label = "",
    //optionDisplay = true,
    styleDropdownProps = {},
    className = "",
    onBlur = () => ''
  } = props

  const classes = usestyles()
  return (
    <>
      <Autocomplete
        name={name}
        className={className}
        ListboxProps={styleDropdownProps}
        options={list}
        value={
          list?.length > 0
            ? _.isEmpty(selectedValue)
              ? defaultValue
              : selectedValue
            : ""
        }
        classes={{ paper: classes.paper }}
        onChange={onChangeEvent}
        disabled={disabled}
        getOptionLabel={(option) =>
          _.isObject(option) ? option?.name : option
        }
        open={popupOpen}
        onInputChange={onInputChangeEvent}
        onOpen={() => {
          setPopupOpen(true)
        }}
        onClose={() => {
          setPopupOpen(false)
        }}
        onBlur={onBlur}
        // disabled={disabled}
        disableClearable={disableClearable}
        style={style}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            variant="outlined"
            // onBlur={onBlur}
            className={classNameTextField}
            placeholder={placeholder}
            error={Boolean(touchedControl && errorControl)}
            helperText={touchedControl && errorControl}
          // InputProps={{
          //     style: { height:name==="AuditLog"||name==="ApplicationLog"?"44px":null},
          //   }}
          />
        )}
      />
    </>
  )
}
export { DropdownFilter }
export default DropdownFilter
